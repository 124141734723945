import React, { useEffect } from 'react'
import {
  useUser,
  PackageCard,
  LeshenPhoneCTA,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import { Columns, Stack, Typography, Modal } from '@leshen/ui'
import { Link as GatsbyLink } from 'gatsby'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

const ZipPackageSection = () => {
  const { userData, userLocation, setUserData } = useUser()

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(userLocation.zipCode, 'internet')

        setUserData({
          loading: false,
          showModal: false,
          highspeedCenturylinkPackages: packageData,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <>
      <Modal
        isShowing={availablePlans?.showModal || false}
        onClose={() =>
          setUserData({
            loading: false,
            showModal: false,
            highspeedCenturylinkPackages:
              availablePlans?.highspeedCenturylinkPackages,
          })
        }
        size="lg"
        closeTimeoutMS={0}
      >
        {availablePlans?.highspeedCenturylinkPackages?.packages?.length === 0 &&
          !arePackagesLoading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2">
                {`CenturyLink doesn't have services in your area, but our trained
              agents can help you find another option!`}
              </Typography>
              <LeshenPhoneCTA color="primary">Call an Agent</LeshenPhoneCTA>
            </div>
          )}

        {availablePlans?.highspeedCenturylinkPackages?.packages?.length >= 1 &&
          !arePackagesLoading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2">
                CenturyLink is available in your area!
              </Typography>
              <Stack spacing="xxl">
                <Columns>
                  {availablePlans.highspeedCenturylinkPackages.packages.map(
                    (data) => (
                      <PackageCard
                        label={data.label}
                        packageData={{
                          ...data.brandy,
                        }}
                        content={
                          <>
                            <LinkButton
                              to="/cart"
                              CustomLink={GatsbyLink}
                              color="primary"
                            >
                              Order Online
                            </LinkButton>
                            {/* Disabled lint line due to Gatsby api named
                            variable */}
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            <LeshenPhoneCTA color="primary">
                              Call
                            </LeshenPhoneCTA>
                          </>
                        }
                        key={data.label}
                      />
                    )
                  )}
                </Columns>
              </Stack>
            </div>
          )}

        {/**
         * If userData is not null, and its `loading` property is set to false
         * by availabilitySubmit, then a fetch request has just completed.
         * That in combination with having no beam package data should mean
         * that there are no packages for the location the user searched.
         */}
        {availablePlans &&
          !availablePlans.loading &&
          availablePlans?.highspeedCenturylinkPackages === null && (
            <>
              <Typography variant="h2">
                {`We're having trouble locating service options for your area.`}
              </Typography>
              <p>
                Give us a call and one of our Internet specialists can help get
                you connected!
              </p>
            </>
          )}
      </Modal>
    </>
  )
}

export default ZipPackageSection
